import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { env } from "src/app/env";
import {SpeedTestService} from 'ng-speed-test';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(public http: HttpClient, public httpParams: HttpParams,private speedTestService:SpeedTestService,private alertController: AlertController,) {
    //     this.checkConnectionInternet().subscribe((speed)=>{
    //         if(speed<=10){
    //             console.error("Internet Slow: "+ speed+"mpbs")
    //             this.showAlertErrorInternet(speed)
    //         }
    //         console.log('Your speed is ' + speed);
    //    })
    }


    getSchedules(plate = '',type = 1) {
        return this.http.get(env.API_BASE_URL + 'schedules?plate='+plate+'&type='+type);
    }

    detailSchedule(id) {
        return this.http.get(env.API_BASE_URL + 'schedules/'+id);
    }

    getServices() {
        return this.http.get(env.API_BASE_URL + 'services');
    }

    startRoute(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/route/start', data);
    }


    updateServiceSchedule(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/updateService', data);
    }

    getScheduleChecklist(id,type) {
        return this.http.get(env.API_BASE_URL + 'schedules/'+id+'/checklists?type='+type);
    }

    detailsPhotos(id,type) {
        return this.http.get(env.API_BASE_URL + 'schedules/'+id+'/photos?type='+type);
    }


    getSchedulePhotos(id,type) {
        return this.http.get(env.API_BASE_URL + 'schedules/'+id+'/checklists/photos?type='+type);
    }

    saveScheduleChecklist(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/checklist', data);
    }

    saveScheduleChecklistSignature(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/checklist/signature', data);
    }

    saveScheduleDocument(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/document', data);
    }

    saveSchedulePhoto(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/photos', data);
    }

    saveScheduleTracker(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/trackers', data);
    }

    positionTracker(id,control_id,confirmDelete) {
        return this.http.get(env.API_BASE_URL +'schedules/'+id+'/trackers/position?control_id='+control_id+(confirmDelete?'&confirmDelete=1':''));
    }

    battery(id,control_id,confirmDelete) {
        return this.http.get(env.API_BASE_URL +'schedules/'+id+'/trackers/battery?control_id='+control_id+(confirmDelete?'&confirmDelete=1':''));
    }

    confirmRemoveTrackers(id) {
        return this.http.get(env.API_BASE_URL +'schedules/'+id+'/trackers/remove/validate');
    }

    removeTracker(id,control_id,data={}) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/trackers/remove/'+control_id,data);
    }

    saveScheduleUpdateTracker(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/trackers/update', data);
    }


    validateToken(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/validateToken', data);
    }

    searchEquipmentByCode(code) {
        return this.http.get(env.API_BASE_URL + 'equipments/search?code='+code);
    }

    detailsTrackers(id) {
        return this.http.get(env.API_BASE_URL + 'schedules/'+id+'/trackers');
    }


    getInstallationLocations(id) {
        return this.http.get(env.API_BASE_URL + 'schedules/'+id+'/installationLocations');
    }


    getInstallationTypes(id) {
        return this.http.get(env.API_BASE_URL + 'schedules/'+id+'/installationTypes');
    }


    saveScheduleChanges(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/checklist/confirmChanges', data);
    }

    finish(id) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/finish',{});
    }

    updatePlate(id,data) {
        return this.http.post(env.API_BASE_URL +'schedules/'+id+'/vehicles/plate/update', data);
    }

    checkConnectionInternet(){
        return this.speedTestService.getMbps()
    }

    async showAlertErrorInternet(speed){
        const alert = await this.alertController.create({
            header: 'Internet Instável!',
            message: "Foi detectada que a velocidade da sua internet é: "+speed.toFixed(2)+"mpbs! Para o pleno funcionamento do app tenha uma internet de pelo menos 10mpbs",
            backdropDismiss:false,
            buttons: [
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        alert.dismiss()
                        window.location.reload();
                    },
                }
            ],
        });
        await alert.present();
    }

}
